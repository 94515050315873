// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-marinda-gatsby-theme-recipes-pages-404-js": () => import("./../../../src/@marinda/gatsby-theme-recipes/pages/404.js" /* webpackChunkName: "component---src-marinda-gatsby-theme-recipes-pages-404-js" */),
  "component---src-marinda-gatsby-theme-recipes-templates-recipe-js": () => import("./../../../src/@marinda/gatsby-theme-recipes/templates/recipe.js" /* webpackChunkName: "component---src-marinda-gatsby-theme-recipes-templates-recipe-js" */),
  "component---src-marinda-gatsby-theme-recipes-templates-recipes-js": () => import("./../../../src/@marinda/gatsby-theme-recipes/templates/recipes.js" /* webpackChunkName: "component---src-marinda-gatsby-theme-recipes-templates-recipes-js" */),
  "component---src-marinda-gatsby-theme-recipes-templates-tagged-recipes-js": () => import("./../../../src/@marinda/gatsby-theme-recipes/templates/taggedRecipes.js" /* webpackChunkName: "component---src-marinda-gatsby-theme-recipes-templates-tagged-recipes-js" */)
}


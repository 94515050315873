module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"D:\\Gatsby\\drausinger-dev\\node_modules\\@marinda\\gatsby-theme-recipes\\src\\components\\MarkdownPagesLayout.js","pages":"D:\\Gatsby\\drausinger-dev\\node_modules\\@marinda\\gatsby-theme-recipes\\src\\components\\MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rezepte","short_name":"Rezepte","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"D:\\Gatsby\\drausinger-dev\\src\\images\\favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"a1f8f2fc76edd9e347570fe0f644e125"},
    },{
      plugin: require('../node_modules/@marinda/gatsby-theme-recipes/gatsby-browser.js'),
      options: {"plugins":[],"title":"Rezepte","shortTitle":"Rezepte","iconPath":"./src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/rezepte/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
